let block = document.querySelector(".js-circle-time");

if(block !== null) {


    let timer = document.querySelector(".timer"),
        year = document.querySelector(".js-circle-year"),
        data = document.getElementsByClassName("js-years"),
        container = document.querySelector(".container-descriptions"),
        month = document.querySelector(".js-month"),
        times = document.getElementsByClassName("js-time-date"),
        arrDates = [ {
            "current" : "1967",
            "month" : "",
            "other" : ["", "", 1972, 1989]
        },
            {
            "current" : "1972",
            "month" : "",
            "other" : ["", 1967, 1989, 1990]
        },
            {
            "current" : "1989",
            "month" : "октябрь",
            "other" : ["1967", "1972", "1990", "1990"]
        }, {
            "current" : "1990",
            "month" : "июнь",
            "other" : ["1972", "1989", "1990", "1990"]
        }, {
            "current" : "1990",
            "month" : "август",
            "other" : ["1989", "1990", "1990", "1990"]
        }, {
            "current" : "1990",
            "month" : "сентябрь",
            "other" : ["1990", "1990", "1990", "1991"]
        }, {
            "current" : "1990",
            "month" : "25 октября",
            "other" : ["1990", "1990", "1991", "1991"]
        }, {
            "current" : "1991",
            "month" : "01 февраля",
            "other" : ["1990", "1990", "1991", "1991"]
        }, {
            "current" : "1991",
            "month" : "02 февраля",
            "other" : ["1990", "1991", "1991", "1992"]
        },{
            "current" : "1991",
            "month" : "март",
            "other" : ["1991", "1991", "1992", "1994"]
        },{
            "current" : "1992",
            "month" : "",
            "other" : ["1991", "1991", "1994", "1995"]
        },{
            "current" : "1994",
            "month" : "",
            "other" : ["1991", "1992", "1995", "1995 – 2005"]
        },{
            "current" : "1995",
            "month" : "",
            "other" : ["1992", "1994", "1995 – 2005", "1996"]
        },{
            "current" : "1995 ",
            "month" : "–2005",
            "other" : ["1994", "1995", "1996", "1996"]
        },{
            "current" : "1996 ",
            "month" : "",
            "other" : ["1995", "1995 – 2005", "1997 – 1999", "1999"]
        },{
            "current" : "1997",
            "month" : "-1999",
            "other" : ["1995 – 2005", "1996", "1999", "2000"]
        },{
            "current" : "1999",
            "month" : "04 декабря",
            "other" : ["1996", "1997 – 1999", "2000", "2001"]
        },{
            "current" : "2000",
            "month" : "",
            "other" : ["1997 – 1999", "1999", "2001", "2003"]
        },{
            "current" : "2001",
            "month" : "",
            "other" : ["1999", "2000", "2003", "2004"]
        },{
            "current" : "2003",
            "month" : "июнь",
            "other" : ["2000", "2001", "2004", "2005"]
        },{
            "current" : "2004",
            "month" : "",
            "other" : ["2001", "2003", "2005", "2005"]
        },{
            "current" : "2005",
            "month" : "",
            "other" : ["2003", "2004", "2005", "2007"]
        }, {
            "current" : "2005",
            "month" : "май",
            "other" : ["2004", "2005", "2005", "2007"]
        }, {
            "current" : "2007",
            "month" : "февраль 14-18",
            "other" : ["2005", "2005", "2007", "2008"]
        },{
            "current" : "2007",
            "month" : "апрель",
            "other" : ["2007", "2005", "2008", "2008"]
        },{
            "current" : "2008",
            "month" : "",
            "other" : ["2007", "2007", "2008", "2010"]
        },{
            "current" : "2008",
            "month" : "",
            "other" : ["2007", "2008", "2010", ""]
        },{
            "current" : "2010",
            "month" : "февраль",
            "other" : ["2008", "2008", "2011", "2011"]
        },{
            "current" : "2011",
            "month" : "июль",
            "other" : ["2008", "2010", "2011", "2011"]
        },{
            "current" : "2011",
            "month" : "октябрь",
            "other" : ["2010", "2011", "2011", ""]
        },{
            "current" : "2011",
            "month" : "ноябрь",
            "other" : ["2011", "2011", "2012", "2013"]
        },{
            "current" : "2012",
            "month" : "октябрь",
            "other" : ["2011", "2011", "2013", "2014"]
        },{
            "current" : "2013",
            "month" : "январь",
            "other" : ["2011", "2012", "2014", "2014"]
        },{
            "current" : "2014",
            "month" : "",
            "other" : ["2012", "2013", "2014", "2014"]
        },{
            "current" : "2014",
            "month" : "апрель",
            "other" : ["2013", "2014", "2014", "2014 -2015"]
        },{
            "current" : "2014",
            "month" : "июнь",
            "other" : ["2014", "2014", "2014 -2015", "2015"]
        },{
            "current" : "2014 -2015",
            "month" : "",
            "other" : ["2014", "2014", "2015", "2016"]
        },{
            "current" : "2015",
            "month" : "",
            "other" : ["2014", "2014 -2015", "2016", "2017"]
        },{
            "current" : "2016",
            "month" : "",
            "other" : ["2014 -2015", "2015", "2017", "2017"]
        },{
            "current" : "2017",
            "month" : "апрель",
            "other" : ["2015", "2016", "2017", "2017"]
        },{
            "current" : "2017",
            "month" : "сентябрь",
            "other" : ["2016", "2017", "2017", "2017"]
        },{
            "current" : "2017",
            "month" : "сентябрь",
            "other" : ["2017", "2017", "2017", "2018"]
        },{
            "current" : "2017",
            "month" : "сентябрь",
            "other" : ["2017", "2017", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "апрель",
            "other" : ["2017", "2017", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "май",
            "other" : ["2017", "2018", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "июнь",
            "other" : ["2018", "2018", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "июль",
            "other" : ["2018", "2018", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "сентябрь",
            "other" : ["2018", "2018", "2018", "2018"]
        },{
            "current" : "2018",
            "month" : "октябрь",
            "other" : ["2018", "2018", "2018", "2019 "]
        },{
            "current" : "2018",
            "month" : "декабрь",
            "other" : ["2018", "2018", "2019 ", "2019"]
        },{
            "current" : "2019",
            "month" : "",
            "other" : ["2018", "2018", "2019", "2019"]
        },{
            "current" : "2019",
            "month" : "январь",
            "other" : ["2018", "2019", "2019", "2019"]
        },{
            "current" : "2019",
            "month" : "апрель",
            "other" : ["2019", "2019", "2019", "2019"]
        },{
            "current" : "2019",
            "month" : "октябрь",
            "other" : ["2019", "2019", "2019", "2019"]
        },{
            "current" : "2019",
            "month" : "октябрь - ноябрь",
            "other" : ["2019", "2019", "2019", "2020"]
        },{
            "current" : "2019",
            "month" : "октябрь",
            "other" : ["2019", "2019", "2020", "2021"]
        },{
            "current" : "2020",
            "month" : "",
            "other" : ["2019", "2019", "2021", ""]
        },{
            "current" : "2021",
            "month" : "",
            "other" : ["2019", "2020", "", ""]
        }
    ];


    function updateBox (time) {

        let pxScroll = container.scrollTop;

        if ( time === 0 ) {
            let heigth = 30;
            for (let i = 0; data.length - ( data.length - 1 ) > i; i++) {
                heigth += data[i].clientHeight + 30;
            }



            if ( pxScroll < heigth ) {
                timer.style.transform = "rotate(calc(" + time + "*45deg))";
                year.textContent = arrDates[time]["current"];
                month.textContent = arrDates[time]["month"];
                data[time].classList.add("is-active");


                for (let i = 0; times.length > i; i++) {

                    times[i].textContent = arrDates[time]["other"][i];

                }
            } else if (data[time].classList.contains("is-active")) {
                timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                data[time].classList.remove("is-active");
            }

        } else if (time === ( data.length - 1 )) {

            let heigth = 30;

            for (let i = 0; data.length  - 1 > i; i++) {
                heigth += data[i].clientHeight + 30;
            }


            if (heigth < pxScroll) {

                timer.style.transform = "rotate(calc(" + time + "*45deg))";
                year.textContent = arrDates[time]["current"];
                month.textContent = arrDates[time]["month"];
                data[time].classList.add("is-active");


                for (let i = 0; times.length > i; i++) {

                    times[i].textContent = arrDates[time]["other"][i];

                }

            } else if (data[time].classList.contains("is-active")) {
                timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                data[time].classList.remove("is-active");
            }
        }else {

            let heigthMin = 30,
                heigthMax = 30;

            for (let i = 0;  time > i; i++) {

                heigthMin += data[i].clientHeight + 30;
            }

            for (let i = 0;  time + 1 > i; i++) {
                heigthMax += data[i].clientHeight + 30;
            }


            if ((heigthMin <= pxScroll ) &&
                (pxScroll < heigthMax  )) {


                timer.style.transform = "rotate(calc(" + time + "*45deg))";
                year.textContent = arrDates[time]["current"];
                month.textContent = arrDates[time]["month"];
                data[time].classList.add("is-active");


                for (let i = 0; times.length > i; i++) {

                    times[i].textContent = arrDates[time]["other"][i];

                }

            } else if (data[time].classList.contains("is-active")) {
                timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                data[time].classList.remove("is-active");
            }

        }

    }


    for (let i = 0; arrDates.length > i; i++ ) {
        container.addEventListener("scroll", () => {updateBox(i)});
    }

}

