require('../scss/app.scss');
require('../fonts/Roboto/stylesheet.css');
require("glightbox/dist/css/glightbox.css") ;

window.noZensmooth = true;

require('chart.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/header.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/swiper.js');
require('./common/footer.js');
require('./common/left_bar.js');
require('./common/top_bar.js');
require('./common/right_bar.js');
require('./common/inter-map.js');
require('./common/pages/app.js');
require('./common/scrollbar_custom.js');
require('glightbox/dist/js/glightbox.min.js');