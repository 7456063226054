import ZoomSwiper from 'swiper';

let swiper = document.querySelector('.js-swiper-zoom');

if(swiper !== null) {

    let zoomSwiper = new ZoomSwiper('.js-swiper-zoom', {
        spaceBetween: 0,
        slidesPerView: 2,
        centeredSlides: true,
        simulateTouch: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                spaceBetween: 30
            }
        }
    });




    let slides = document.querySelectorAll('.swiper-slide'),
        cheackSlide,
        body = document.querySelector('body'),
        page =  document.querySelector('.js-unique-news');
    for (let i = 0; i < slides.length; ++i) {
        slides[i].addEventListener('click', function(event) {
            if (!(swiper.classList.contains('fullscreen'))) {


            event.target.parentNode.parentNode.classList.add('fullscreen');
            cheackSlide = i;
            page.classList.add('is-active');
            body.classList.add('is-scroll-hidden');

            zoomSwiper = new ZoomSwiper('.js-swiper-zoom', {
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                initialSlide: i,
                centeredSlides: true,
                slidesPerView: 1,
                simulateTouch: false,
                allowTouchMove: false

            });
                setTimeout(function() {
                    zoomSwiper.update();
                }, 200);

            }
        }, false);

    }

    function contains(arr, elem) {
        for (var z = 0; z < arr.length; z++) {
            if (arr[z].classList.contains(elem)) {
                return z;
            }
        }
    }

    let closeButtons = document.querySelector('.js-close-button');
        closeButtons.addEventListener('click', function(event) {
            let number = contains(slides, 'swiper-slide-active')

            console.log(number);

            let fullScreenElements = document.querySelectorAll('.fullscreen');
            for (let x = 0; x < fullScreenElements.length; ++x) {
                fullScreenElements[x].classList.remove('fullscreen');

                page.classList.remove('is-active');
                body.classList.remove('is-scroll-hidden');
                zoomSwiper = new ZoomSwiper('.js-swiper-zoom', {
                    spaceBetween: 0,
                    slidesPerView: 2,
                    centeredSlides: true,
                    initialSlide: number,
                    simulateTouch: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 1
                        },
                        768: {
                            spaceBetween: 30
                        }
                    }

                });
            }
        });

}

