import SimpleBar from "simplebar";


let simplebars = Array.from(document.querySelectorAll('[data-simplebar-custom]'));

simplebars.forEach((el)=>{
    new SimpleBar(el, {
        forceVisible: "x",
        autoHide: false,
    });
});
