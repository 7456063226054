let menu = document.querySelector('.right-bar');

if(menu !== null) {
    window.addEventListener('scroll', function () {
        let height =  document.body.scrollHeight,
            heightWindow = window.innerHeight,
            widthWindow = window.innerWidth,
            scrollTop =  document.documentElement.scrollTop,
            scrollBottom = height - heightWindow - scrollTop,
            bar = document.querySelector('.js-right-bar');

        if (widthWindow > 768) {
            if ((scrollBottom <= 690) &&
                (bar.classList.contains('is-static') === false)) {

                bar.classList.add('is-static');

            }else if ((scrollBottom > 690) &&
                (bar.classList.contains('is-static') === true)) {

                bar.classList.remove('is-static');
            }
        } else {
            if ((scrollBottom <= 1350) &&
                (bar.classList.contains('is-static') === false)) {

                bar.classList.add('is-static');

            }else if ((scrollBottom > 1350) &&
                (bar.classList.contains('is-static') === true)) {

                bar.classList.remove('is-static');
            }
        }



    })
}