let menu = document.querySelector('.top-bar');

if(menu !== null) {

    let btn = document.querySelector('[data-list-link-open]');

    btn.addEventListener('click', function () {

        this.classList.toggle('is-open')
    })

}