let steps = document.querySelector('[data-open-certificate-steps]');

if(steps !== null) {


    window.addEventListener('scroll', function () {
        let pxScroll =  document.documentElement.scrollTop,
            widthWindow = window.innerWidth;
        console.log(steps.offsetTop);
        console.log(pxScroll);
        if ((widthWindow > 1200) &&
            (steps.classList.contains('is-active') === false)) {


            if (pxScroll > steps.offsetTop - 100) {



                steps.classList.add('is-active');


            }

        }else if ((widthWindow > 768) &&
            (steps.classList.contains('is-active') === false)) {


            if (pxScroll > steps.offsetTop - 100) {

                steps.classList.add('is-active');


            }
        }
    })
}
