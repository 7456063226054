let map = document.querySelector('.js-inter-menu-popup');

if(map !== null) {

    let btnOpen = document.querySelector('[data-open-inter-map]'),
        blockBody = document.querySelector('body'),
        btnClose = document.querySelector('[ data-close-inter-map]');

    btnOpen.addEventListener('click', function(e) {
        e.preventDefault();
        map.classList.add('is-active');
        blockBody.classList.add('is-scroll-hidden');
    });

    btnClose.addEventListener('click', function(e) {
        e.preventDefault();
        map.classList.remove('is-active');
        blockBody.classList.remove('is-scroll-hidden');
    });


}