let benefits = document.querySelector('[data-benefits-open]');

if(benefits !== null) {


    window.addEventListener('scroll', function () {
        let pxScroll =  document.documentElement.scrollTop,
            widthWindow = window.innerWidth;
        if ((widthWindow > 1200) &&
            (benefits.classList.contains('is-active') === false)) {


            if (pxScroll > 2700) {

                benefits.classList.add('is-active');


            }

        }else if ((widthWindow > 768) &&
            (benefits.classList.contains('is-active') === false)) {


            if (pxScroll > 5000) {

                benefits.classList.add('is-active');


            }
        }
    })
}

