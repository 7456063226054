let table = document.querySelector('.js-stage-cert');

if(table !== null) {

    let pointA = document.getElementsByClassName('js-column-a'),
        pointB = document.getElementsByClassName('js-column-b'),
        pointC = document.getElementsByClassName('js-column-c'),
        pointD = document.getElementsByClassName('js-column-d'),
        columnA = document.querySelector('.js-column-hover-a'),
        columnB = document.querySelector('.js-column-hover-b'),
        columnC = document.querySelector('.js-column-hover-c'),
        columnD = document.querySelector('.js-column-hover-d'),
        textA = document.getElementsByClassName('js-text-a'),
        textB = document.getElementsByClassName('js-text-b'),
        textC = document.getElementsByClassName('js-text-c'),
        textD = document.getElementsByClassName('js-text-d'),
        mobileBtn = document.querySelectorAll('[data-table-btn]'),
        arrPoints = [ pointA, pointB, pointC, pointD],
        arrColumn = [ columnA, columnB, columnC, columnD],
        arrText = [ textA, textB, textC, textD],
        arrClass = ['is-active-a', 'is-active-b', 'is-active-c', 'is-active-d'],
        widthWindow = window.innerWidth;

    function addClass(number) {
        for (let j = 0; j < pointA.length; j++ ) {
            arrPoints[number][j].classList.add('is-active');
            try {
                arrText[number][j].classList.add(arrClass[number]);
            }
            catch(err) { }


        }

    }



    function removeClass(number) {
        for (let j = 0; j < pointA.length; j++ ) {
            arrPoints[number][j].classList.remove('is-active');
            try {
                arrText[number][j].classList.remove(arrClass[number]);
            }
            catch(err) { }
        }

    }


    for (let j = 0; j < arrColumn.length; j++) {
        if (widthWindow > 1200) {
            arrColumn[j].addEventListener('mouseover', function () {
                addClass(j);
            });
            arrColumn[j].addEventListener('mouseout', function () {
                removeClass(j);
            });
        }
    }

    for (let j = 0; j < mobileBtn.length; j++) {
        if (widthWindow <= 768) {
            mobileBtn[j].addEventListener('click', function () {
                mobileBtn[j].classList.toggle("btn-open");
                let parent = mobileBtn[j].parentNode;
                parent.classList.toggle("border-visible");

            });

        }

    }

    function removeAllAnother(arr,number) {
        arr.forEach( function(item, index) {
            if (number !== index) {
                    removeClass(index);
            }
        })

    }

    if (widthWindow <= 1200) {
        arrPoints.forEach( function(item, index) {
            for (let j = 0; j < item.length; j++) {
                item[j].addEventListener('click', function () {
                    addClass(index);
                    removeAllAnother(arrPoints,index)
                });
            }
        });
    }



    // for (let j = 0; j < arrColumn.length; j++) {
    //
    // }

}

