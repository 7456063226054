let map = document.querySelector('.inter-map');


let getLinks = () => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/get-map-links', false);
    xhr.send();
    if (xhr.status != 200) {
        console.log(xhr.status + ': ' + xhr.statusText);
    } else {
        return xhr.responseText;
    }
}

let data = JSON.parse(getLinks());

if (map) {
    let element = document.getElementsByClassName('js-color'),
        card = document.getElementsByClassName('js-card'),
        arrowCard = document.getElementsByClassName('js-arrow-card'),
        color1 = 'color-blue',
        color2 = 'color-orange',
        color3 = 'color-purple',
        disabled1 = 'js-blue-disabled',
        disabled2 = 'js-orange-disabled',
        disabled3 = 'js-purple-disabled',
        btnColor = document.getElementsByClassName('js-map-btn'),
        arrColor = [color1, color2, color3],
        arrDisabled = [disabled1, disabled2, disabled3];


    let data = null;

    let getLinks = () => {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/get-map-links', false);
        xhr.onload = function () {
            if (xhr.status != 200) {
                console.log(xhr.status + ': ' + xhr.statusText);
            } else {
                data = JSON.parse(xhr.responseText);
                addClass(0);
                for (let i = 0; i < btnColor.length; i++) {
                    btnColor[i].addEventListener('click', function () {
                        addClass(i);
                    });
                }
            }
        };
        xhr.send();
    }

    getLinks();


    function addClass(i) {
        for (let j = 0; j < element.length; j++) {
            for (let z = 0; z < arrColor.length; z++) {
                element[j].classList.remove(arrColor[z]);
            }
        }

        for (let j = 0; j < element.length; j++) {
            element[j].classList.add(arrColor[i]);
            if (element[j].classList.contains('js-blue-disabled') &&
                element[j].classList.contains('js-blue-disabled')) {

            }
        }

        for (let j = 0; j < arrColor.length; j++) {
            btnColor[j].classList.remove('is-active');
        }

        let currentData = btnColor[i].getAttribute('data-type');
        btnColor[i].classList.add('is-active');

        for (let j = 0; j < card.length; j++) {

            let link = data[currentData][j];
            let elemLink = card[j].querySelector('a');
            elemLink.setAttribute('href', link);

            card[j].classList.add('is-active');
            if (card[j].classList.contains(arrDisabled[i])) {
                card[j].classList.remove('is-active');

            }


        }

        for (let j = 0; j < arrowCard.length; j++) {
            arrowCard[j].classList.add('is-active');
            if (arrowCard[j].classList.contains(arrDisabled[i])) {
                arrowCard[j].classList.remove('is-active');

            }


        }


    }
}



