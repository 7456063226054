window.initContactsMap = function (lat, lng, pin) {

    ymaps.ready(function () {
        const map = new ymaps.Map('yandex-map', {
                center: [lat, lng],
                zoom: 8
            }),
            pin = new ymaps.Placemark( [lat, lng], {}, {
                preset: 'islands#redHomeIcon'
            });

        map.geoObjects.add(pin);

    });
}
