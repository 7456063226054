let btn = document.querySelector('.js-partners-accordion');

if(btn !== null) {

    btn = document.getElementsByClassName('js-partners-btn');

    let info = document.getElementsByClassName('js-partners-accordion');


    for (let i = 0; i < btn.length; i++) {
        btn[i].addEventListener('click', function () {
            info[i].classList.toggle("is-open");
            this.classList.toggle("is-open");
            if (this.classList.contains('is-open')) {
                this.textContent = "Скрыть"
            } else {
                this.textContent = "Подробнее"
            }

        });

    }


    // let closeButtons = document.querySelectorAll('.close-button');
    // for (let y = 0; y < closeButtons.length; ++y) {
    //     closeButtons[y].addEventListener('click', function(event) {
    //         let fullScreenElements = document.querySelectorAll('.fullscreen');
    //         for (let x = 0; x < fullScreenElements.length; ++x) {
    //             fullScreenElements[x].classList.remove('fullscreen');
    //             page.classList.remove('is-active');
    //             body.classList.remove('is-scroll-hidden');
    //             zoomSwiper = new ZoomSwiper('.js-swiper-zoom', {
    //                 spaceBetween: 30,
    //                 slidesPerView: 2,
    //                 centeredSlides: true,
    //                 navigation: {
    //                     nextEl: '.swiper-button-next',
    //                     prevEl: '.swiper-button-prev'
    //                 },
    //                 initialSlide: cheackSlide,
    //                 simulateTouch: false
    //             });
    //         }
    //     });
    // }
}

