import MainSwiper from 'swiper';
import PartnersSwiper from 'swiper';

let block = document.querySelector('.main-slider');

let background = document.querySelector('[data-background]');
let buttonMore = document.querySelector('[data-more]');

if (block !== null) {

let mainSwiper = new MainSwiper('.main-slider .swiper-container', {
    slidesPerView: 1,
    height: 690,
    spaceBetween: 30,
    lazy: {
        loadPrevNext: true
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
    },
    navigation: {
        nextEl: '.main-slider .swiper-button-next',
        prevEl: '.main-slider .swiper-button-prev'
    },
    on: {
        init: function () {
            let activeSlide = this.slides[this.activeIndex];
            buttonMore.setAttribute("href", activeSlide.getAttribute("data-link"));
            background.style.backgroundImage = `url(${activeSlide.getAttribute("data-image")})`;
        },
        slideChange: function() {
            let activeSlide = this.slides[this.activeIndex];
            buttonMore.setAttribute("href", activeSlide.getAttribute("data-link"));
            background.style.backgroundImage = `url(${activeSlide.getAttribute("data-image")})`;
        }
    }

});

let partnersSwiper = new PartnersSwiper('.swiper-partners .swiper-container', {
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
    },
    navigation: {
        nextEl: '.swiper-partners .swiper-button-next',
        prevEl: '.swiper-partners .swiper-button-prev'
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        768: {
            slidesPerView: 2
        },
        1200: {
            slidesPerView: 4
        }
    }
});



partnersSwiper.update();
}




