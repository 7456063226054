//let scrollLink = document.querySelector('[data-footer-scroll-link]');
let mobilScrollLink = document.querySelector('[data-mobile-scroll-link]');
let scrollMobile = document.querySelector('.scroll-mobile');

mobilScrollLink.addEventListener('click', function(e) {
    scrollMobile.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    })
});


// scrollLink.addEventListener('click', function(e) {
//     e.preventDefault();
//     let header = document.querySelector('header');
//     header.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start'
//     })
// });

window.addEventListener('scroll', function () {
    let height =  document.body.scrollHeight,
        widthWindow = window.innerHeight,
        scrollTop =  document.documentElement.scrollTop,
        scrollBottom = height - widthWindow - scrollTop;

    if ((scrollTop >= 900) &&
    (mobilScrollLink.classList.contains('is-active') === false)) {
        mobilScrollLink.classList.add('is-active');   
    }else if ((scrollTop < 900) &&
        (mobilScrollLink.classList.contains('is-active') === true)) {
        mobilScrollLink.classList.remove('is-active');
    }
    // if ((scrollBottom <= 200) &&
    //     (scrollLink.classList.contains('is-active') === false)) {
    //     scrollLink.classList.add('is-active');
    // }else if ((scrollBottom > 300) &&
    //     (scrollLink.classList.contains('is-active') === true)) {
    //     mobilScrollLink.classList.remove('is-active');
    //     scrollLink.classList.remove('is-active');
    // }
});
