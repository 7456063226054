let steps = document.querySelector('[data-open-steps]');

if(steps !== null) {


    window.addEventListener('scroll', function () {
        let pxScroll =  document.documentElement.scrollTop,
            widthWindow = window.innerWidth;
        if ((widthWindow > 1200) &&
            (steps.classList.contains('is-active') === false)) {


            if (pxScroll > 1400) {


                steps.classList.add('is-active');


            }

        }else if ((widthWindow > 768) &&
            (steps.classList.contains('is-active') === false)) {


            if (pxScroll > 1500) {
                steps.classList.add('is-active');


            }
        }
    })
}

