
function  initMobileMenu() {
    let btnBurgerOpen =document.querySelectorAll('[data-mobile-menu-open]'),
        leftBar = document.querySelector('.js-header-left-bar'),
        burgerMenu = document.querySelector('[data-mobile-menu-body]'),
        blockBody = document.querySelector('body'),
        btnBurgerClose = document.querySelector('[data-mobile-menu-close]'),
        menuArrow = document.getElementsByClassName('js-btn-arrow'),
        menuPoints = document.getElementsByClassName('js-mob-menu-point');


    function removeAll (str, element) {
        for ( let i of menuPoints) {
            if ((i.classList.contains(str)) &&
                (i !== element)
            ) {
                i.classList.remove('btn-open')
            }
        }
    }

    for ( let x of menuArrow) {

        x.addEventListener('click', function (e) {
            e.preventDefault()
            let parent = this.closest('.js-mob-menu-point');

            if (parent.classList.contains('lvl0')) {
                removeAll('lvl0', parent);
                parent.classList.toggle('btn-open')

            }else if (parent.classList.contains('lvl1')) {
                removeAll('lvl1', parent);
                parent.classList.toggle('btn-open')
            }
        })


    }

    function openMenu() {

        burgerMenu.classList.toggle('menu-open');
        blockBody.classList.toggle('is-scroll-hidden');
    }

    for ( let y of btnBurgerOpen) {

        y.addEventListener('click', function () {
            openMenu();
            return false;
        });

    }

    btnBurgerClose.addEventListener('click', function () {
        openMenu();
        return false;
    });

    // window.addEventListener('scroll', function () {
    //     let height =  document.body.scrollHeight,
    //         heightWindow = window.innerHeight,
    //         scrollTop =  document.documentElement.scrollTop,
    //         scrollBottom = height - heightWindow - scrollTop;

    //     if ((scrollBottom <= 1350) &&
    //         (leftBar.classList.contains('is-hidden') === false)) {

    //         leftBar.classList.add('is-hidden');

    //     }else if ((scrollBottom > 1350) &&
    //         (leftBar.classList.contains('is-hidden') === true)) {

    //         leftBar.classList.remove('is-hidden');
    //     }
    // })


}
initMobileMenu();

export {initMobileMenu};